import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import "../styles.css"
import { Box, Container, FlexList, Logo, NavLink, Space, Text } from "./ui"
import { GatsbyImage } from "gatsby-plugin-image"

interface SideMenuProps {
}

const SideMenu: React.FC<SideMenuProps> = (props) => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsArtCategory {
                nodes {
                    title
                    slug
                    order
                }
            }
            datoCmsGeneral {
                logo {
                    gatsbyImageData(width: 100)
                }
            }
        }
    `);

    return (
        <>
            <FlexList responsiveColumn>
                <Link to="/">
                    <Logo image={data.datoCmsGeneral.logo.gatsbyImageData} alt="kGomes Art Logo" />
                </Link>
                <Text variant="kicker" style={{ whiteSpace: "nowrap" }}>
                    kGomes Art
                </Text>
            </FlexList>

            <FlexList responsiveColumn gap={0} alignItems="start">

                {
                    data.allDatoCmsArtCategory.nodes
                        .sort((x, y) => x.order - y.order)
                        .map((category) => (
                            <Box key={category.slug} paddingY={2}>
                                <NavLink to={`/${category.slug}`}>{category.title}</NavLink>
                            </Box>
                        ))
                }

                <Box paddingY={2}>
                    <NavLink to="/contact">Contact</NavLink>
                </Box>

                <Box paddingY={2}>
                    <NavLink to="/cv">CV</NavLink>
                </Box>
            </FlexList>
        </>
    );
}

export default SideMenu

