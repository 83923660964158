import * as React from "react"
import Head from "./head"
import "../styles.css"
import { Box, Flex } from "./ui"
import SideMenu from "./side-menu"

interface LayoutProps {
    title: string
    description?: string
    image?: { id: string; url: string }
}

const Layout: React.FC<LayoutProps> = (props) => {
    return (
        <>
            <Head {...props} />
            <Flex responsive marginY={3} style={{ alignItems: "flex-start" }}>
                <Box width="fitContent" style={{ alignSelf: "baseline" }} paddingX={3}>
                    <SideMenu />
                </Box>
                <Box width="full">
                    {props.children}
                </Box>
            </Flex>
        </>
    )
}

export default Layout
